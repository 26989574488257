/**  Basic Page CSS **/
body {
    font-weight: 200;
}

header {
    background-color: #337ab7;
}

header a,
footer a {
    color: white;
}

footer {
    font-size: 0.8em;
}

.bg-primary,
.btn-primary {
    background-color: #198819 !important;
    border: inherit;
}

main.page.bg-white {
    margin-bottom: 50px;
}

.qrcode_img {
    width: 300px;
    height: 300px;
}
